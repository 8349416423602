<template>
	<div class="p-fluid">
		<Accordion>
			<AccordionTab :header="$t('GENERAL INFORMATION')">
				<div class="p-grid">
					<div class="p-col-12 p-md-12 p-lg-6">
						<label>{{$t('Name')}}</label>
						<InputText v-model="$store.state.busines.name" type="text" id="name" />
					</div>
					<div class="p-col-12 p-md-12 p-lg-6">
						<label>{{$t('Tradename')}}</label>
						<InputText v-model="$store.state.busines.trade_name" type="text" id="name" />
					</div>
					<div class="p-col-12 p-md-12 p-lg-12">
						<label>{{$t('Residence')}}</label>
						<Textarea v-model="$store.state.busines.address" rows="2"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-5">
						<label>{{$t('Judisdiction')}}</label>
						<InputText v-model="$store.state.busines.judisdiction" type="text" id="name" />
					</div>
					<div class="p-col-12 p-md-12 p-lg-4">
						<label>{{$t('Country of constitution')}}</label>
						<Dropdown v-model="$store.state.busines.country" :options="nationalitys" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-3">
						<label>{{$t('Year of constitution')}}</label>
						<InputMask v-model="$store.state.busines.year" mask="9999"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-5">
						<label>{{$t('Register number')}}</label>
						<InputText v-model="$store.state.busines.register_number" type="text" id="name" />
					</div>            
					<div class="p-col-12 p-md-12 p-lg-4">
						<label>{{$t('Main company activity')}}</label>
						<InputText v-model="$store.state.busines.activity" type="text"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-3">
						<label>{{$t('Telephone')}}</label>
						<InputMask v-model="$store.state.busines.telephone" mask="+99999 999999"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-12">
						<label>{{$t('Website')}}</label>
						<InputText v-model="$store.state.busines.website" type="text"/>
					</div>			
				</div>    
			</AccordionTab>
			<AccordionTab :header="$t('REGISTERED AGENT')">
				<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New(1)"/>
				<div class="p-grid">
					<div class="p-col-12 p-md-12 p-lg-12 p-mr-2 p-mb-2" v-for="(stats, index) in $store.state.busines.agent" :key="index">
						<div class="p-card p-grid">
							<div class="p-col-12 p-md-12 p-lg-11 p-grid">
								<div class="p-col-12 p-md-12 p-lg-12" v-if="stats.del">
									<strong style="color: red">{{$t('Delete')}}</strong>
								</div>
								<div class="p-col-12 p-md-12 p-lg-10" v-if="!stats.del">
									<strong>{{$t('Name')}}: </strong>{{ stats.name }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-2" v-if="!stats.del">
									<strong>{{$t('ID')}}: </strong>{{ stats.ident_passport }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-5" v-if="!stats.del">
									<strong>{{$t('Nationality')}}: </strong>{{ stats.nationality[i18n.locale()] }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-5" v-if="!stats.del">
									<strong>{{$t('Profession')}}: </strong>{{ stats.profession }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-2" v-if="!stats.del">
									<strong>PEP: </strong>{{ stats.pep[i18n.locale()] }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-12" v-if="!stats.del">
									<strong>{{$t('Residence')}}: </strong>{{ stats.residence }}
								</div>
							</div>
							<div class="p-col-12 p-md-12 p-lg-1 p-grid">
								<Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Edit')" @click="Edit(1,stats,index)"  v-if="!stats.del"/>
								<Button icon="pi pi-eye" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('View')" @click="View(stats.doc_passport)"  v-if="!stats.del"/>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Del')" @click="stats.del=false" v-if="stats.del"/>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Del')" @click="stats.del=true" v-else/>
							</div>
						</div>
					</div>
				</div>
			</AccordionTab>
			<AccordionTab :header="$t('DIRECTORS')">
				<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New(2)"/>
				<div class="p-grid">
					<div class="p-col-12 p-md-12 p-lg-12 p-mr-2 p-mb-2" v-for="(stats, index) in $store.state.busines.directors" :key="index">
						<div class="p-card p-grid">
							<div class="p-col-12 p-md-12 p-lg-11 p-grid">
								<div class="p-col-12 p-md-12 p-lg-12" v-if="stats.del">
									<strong style="color: red">{{$t('Delete')}}</strong>
								</div>
								<div class="p-col-12 p-md-12 p-lg-10" v-if="!stats.del">
									<strong>{{$t('Name')}}: </strong>{{ stats.name }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-2" v-if="!stats.del">
									<strong>{{$t('ID')}}: </strong>{{ stats.ident_passport }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-5" v-if="!stats.del">
									<strong>{{$t('Nationality')}}: </strong>{{ stats.nationality[i18n.locale()] }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-5" v-if="!stats.del">
									<strong>{{$t('Profession')}}: </strong>{{ stats.profession }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-2" v-if="!stats.del">
									<strong>PEP: </strong>{{ stats.pep[i18n.locale()] }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-12" v-if="!stats.del">
									<strong>{{$t('Residence')}}: </strong>{{ stats.residence }}
								</div>
							</div>
							<div class="p-col-12 p-md-12 p-lg-1 p-grid">
								<Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Edit')" @click="Edit(2,stats,index)"  v-if="!stats.del"/>
								<Button icon="pi pi-eye" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('View')" @click="View(stats.doc_passport)"  v-if="!stats.del"/>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Del')" @click="stats.del=false" v-if="stats.del"/>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Del')" @click="stats.del=true" v-else/>
							</div>
						</div>
					</div>
				</div>
			</AccordionTab>
			<AccordionTab :header="$t('DATA FROM')">
				<div class="p-grid">
					<div class="p-col-12 p-md-12 p-lg-6">
						<label>{{$t('DATA FROM')}}</label>
						<SelectButton v-model="$store.state.busines.representative" :options="options" :optionLabel="i18n.locale()"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-12">
						<label>{{$t('Indicate whether the Legal Representative, Attorney or the Company itself is or has been the object of investigation or conviction for illegal activities, or money laundering or money laundering or terrorist financing offenses.')}}</label>
					</div>
					<div class="p-col-12 p-md-12 p-lg-4">
						<SelectButton v-model="$store.state.busines.object_investigation" :options="options2" :optionLabel="i18n.locale()"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-8" v-if="$store.state.busines.object_investigation">
						<label v-if="$store.state.busines.object_investigation.id">{{$t('If yes, explain')}}</label>
						<Textarea v-model="$store.state.busines.object_investigation2" rows="2" v-if="$store.state.busines.object_investigation.id"/>
					</div>
				</div>
			</AccordionTab>
			<AccordionTab :header="$t('SHAREHOLDERS')">
				<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New(3)"/>
				<div class="p-grid">
					<div class="p-col-12 p-md-12 p-lg-12 p-mr-2 p-mb-2" v-for="(stats, index) in $store.state.busines.shareholder1" :key="index">
						<div class="p-card p-grid">
							<div class="p-col-12 p-md-12 p-lg-11 p-grid">
								<div class="p-col-12 p-md-12 p-lg-12" v-if="stats.del">
									<strong style="color: red">{{$t('Delete')}}</strong>
								</div>
								<div class="p-col-12 p-md-12 p-lg-10" v-if="!stats.del">
									<strong>{{$t('Name')}}: </strong>{{ stats.name }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-2" v-if="!stats.del">
									<strong>{{$t('ID')}}: </strong>{{ stats.ident_passport }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-5" v-if="!stats.del">
									<strong>{{$t('Nationality')}}: </strong>{{ stats.nationality[i18n.locale()] }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-5" v-if="!stats.del">
									<strong>{{$t('Profession')}}: </strong>{{ stats.profession }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-2" v-if="!stats.del">
									<strong>PEP: </strong>{{ stats.pep[i18n.locale()] }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-12" v-if="!stats.del">
									<strong>{{$t('Residence')}}: </strong>{{ stats.residence }}
								</div>
							</div>
							<div class="p-col-12 p-md-12 p-lg-1 p-grid">
								<Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Edit')" @click="Edit(3,stats,index)"  v-if="!stats.del"/>
								<Button icon="pi pi-eye" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('View')" @click="View(stats.doc_passport)"  v-if="!stats.del"/>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Del')" @click="stats.del=false" v-if="stats.del"/>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Del')" @click="stats.del=true" v-else/>
							</div>
						</div>
					</div>
				</div>
			</AccordionTab>
			<AccordionTab :header="$t('SHAREHOLDERS (than 10% ownership)')">
				<div class="p-grid">
					<div class="p-col-12 p-md-12 p-lg-12">
						<label>{{$t('Make a list of all the owners of your organization that have more than 10% ownership. (If you need space, attach additional sheets)')}}</label>
					</div>					
				</div>
				<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New(4)"/>
				<div class="p-grid">
					<div class="p-col-12 p-md-12 p-lg-12 p-mr-2 p-mb-2" v-for="(stats, index) in $store.state.busines.shareholder2" :key="index">
						<div class="p-card p-grid">
							<div class="p-col-12 p-md-12 p-lg-11 p-grid">
								<div class="p-col-12 p-md-12 p-lg-12" v-if="stats.del">
									<strong style="color: red">{{$t('Delete')}}</strong>
								</div>
								<div class="p-col-12 p-md-12 p-lg-10" v-if="!stats.del">
									<strong>{{$t('Name')}}: </strong>{{ stats.name }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-2" v-if="!stats.del">
									<strong>{{$t('ID')}}: </strong>{{ stats.ident_passport }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-5" v-if="!stats.del">
									<strong>{{$t('Nationality')}}: </strong>{{ stats.nationality[i18n.locale()] }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-5" v-if="!stats.del">
									<strong>{{$t('Profession')}}: </strong>{{ stats.profession }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-2" v-if="!stats.del">
									<strong>PEP: </strong>{{ stats.pep[i18n.locale()] }}
								</div>
								<div class="p-col-12 p-md-12 p-lg-12" v-if="!stats.del">
									<strong>{{$t('Residence')}}: </strong>{{ stats.residence }}
								</div>
							</div>
							<div class="p-col-12 p-md-12 p-lg-1 p-grid">
								<Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Edit')" @click="Edit(4,stats,index)"  v-if="!stats.del"/>
								<Button icon="pi pi-eye" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('View')" @click="View(stats.doc_passport)"  v-if="!stats.del"/>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Del')" @click="stats.del=false" v-if="stats.del"/>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" v-tooltip.bottom="$t('Del')" @click="stats.del=true" v-else/>
							</div>
						</div>
					</div>
				</div>
			</AccordionTab>
			<AccordionTab :header="$t('POLICIES WITH ANNUAL PREMIUM, EQUAL OR GREATER TO USD. 10,000.00')">
				<div class="p-grid">
					<div class="p-col-12 p-md-12 p-lg-8">
						<label>{{$t('The total of the annual premiums you pay are equal to or exceed the USD. 10,000.00')}}</label>
					</div>
					<div class="p-col-12 p-md-12 p-lg-4">
						<SelectButton v-model="$store.state.busines.income" :options="options2" :optionLabel="i18n.locale()"/>
					</div>
				</div>
				<Income :option="options3" v-if="$store.state.busines.income.id"/>
			</AccordionTab>
		</Accordion>
		<div class="p-grid">
			<div class="p-col-12 p-md-12 p-lg-4">
				<div v-if="$store.state.busines.doc_constituent">
					<label>{{ $t('Constituent Document and Amendments') }}</label><br>
					<img style="width: 50px;" src="images/pdf.png" @click="View($store.state.busines.doc_constituent)"/>
					<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="Delimg(1,$store.state.busines.doc_constituent)" v-tooltip.bottom="$t('Delete')"/>
				</div>
				<div v-else>
					<FileUpload :chooseLabel="$t('Constituent Document and Amendments')" uploadLabel="Cargar" mode="basic" :name="'form_'+token" :url="url+'upload.php'"  accept="application/pdf" :maxFileSize="1000000" @upload="Uploader(1,'form_'+token)" :auto="true"/>
				</div>
			</div>	
			<div class="p-col-12 p-md-12 p-lg-4">
				<div v-if="$store.state.busines.doc_pasaporte">
					<label>{{$t('Passport of Attorney in fact')}}</label><br>
					<img style="width: 50px;" src="images/pdf.png" @click="View($store.state.busines.doc_pasaporte)"/>
					<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="Delimg(2,$store.state.busines.doc_pasaporte)" v-tooltip.bottom="$t('Delete')"/>
				</div>
				<div v-else>
					<FileUpload :chooseLabel="$t('Passport of Attorney in fact')" uploadLabel="Cargar" mode="basic" :name="'form_'+token" :url="url+'upload.php'"  accept="application/pdf" :maxFileSize="1000000" @upload="Uploader(2,'form_'+token)" :auto="true"/>
				</div>
			</div>	
			<div class="p-col-12 p-md-12 p-lg-4">
				<div v-if="$store.state.busines.doc_certificate">
					<label>{{ $t('Certificate of incumbency') }}</label><br>
					<img style="width: 50px;" src="images/pdf.png" @click="View($store.state.busines.doc_certificate)"/>
					<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="Delimg(3,$store.state.busines.doc_certificate)" v-tooltip.bottom="$t('Delete')"/>
				</div>
				<div v-else>
					<FileUpload :chooseLabel="$t('Certificate of incumbency')" uploadLabel="Cargar" mode="basic" :name="'form_'+token" :url="url+'upload.php'"  accept="application/pdf" :maxFileSize="1000000" @upload="Uploader(3,'form_'+token)" :auto="true"/>
				</div>
			</div>	
			<div class="p-col-12 p-md-12 p-lg-4">
				<div v-if="$store.state.busines.form">
					<label>{{ $t('Form') }}</label><br>
					<img style="width: 50px;" src="images/pdf.png" @click="View($store.state.busines.form)"/>
					<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="Delimg(4,$store.state.busines.form)" v-tooltip.bottom="$t('Delete')"/>
				</div>
				<div v-else>
					<FileUpload :chooseLabel="$t('Signed form')" uploadLabel="Cargar" mode="basic" :name="'form_'+token" :url="url+'upload.php'"  accept="application/pdf" :maxFileSize="1000000" @upload="Uploader(4,'form_'+token)" :auto="true"/>
				</div>
			</div>	
		</div>
		<Dialog v-model:visible="productDialog" :style="{width: (size*1.5)}" :header="$t('Edit')" :modal="true" class="p-fluid" :maximizable="true">
			<Person :nations="nationalitys" :option="options2" :alls="false"/>
			<template #footer>
				<Button :label="$t('Add')" icon="pi pi-check" class="p-button-secondary" @click="Add()"/>
				<Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="productDialog = false"/>
			</template>
		</Dialog>

	</div>
</template>

<script>
import API from "../service/API";
var Consult = new API('Registration','Uploader');
import Token from "uuid-token-generator";
import { useI18nPlugin } from '@unify/vuex-i18n';
import { defineAsyncComponent } from "@vue/runtime-core";

export default {
	components: {
		Person: defineAsyncComponent(() => import('./Person.vue')),
		Income: defineAsyncComponent(() => import('./Income.vue')),
	},
    props: {
		person: {
			type: Object,
			default: null,
		},
		nations: {
			type: Array,
			default: null,
		},
		option: {
			type: Array,
			default: null,
		},
		option2: {
			type: Array,
			default: null,
		},
		option3: {
			type: Array,
			default: null,
		}
	},
    data() {
        return {
            size: '40vw',
            nationalitys: this.nations,
            options: this.option,
            options2: this.option2,
            options3: this.option3,
			productDialog: false,
			cas: 0,
			newid: false,
			token: null,
			url: process.env.VUE_APP_API,
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
		const tokgen = new Token(256);
        this.token = tokgen.generate();
    },
    methods: {
		View(index){
			window.open(this.url+'upload/'+index, '_blank');
		},
		New(cas){
			this.newid = true;
			this.cas = cas;
			this.$store.state.person = {
				name: null,
				nationality: null,
				residence: null,
				profession: null,
				ident_passport: null,
				doc_passport: null,
				rep: null,
			};
			this.productDialog = true;
		},
		Edit(cas,data,index){
			this.newid = false;
			this.cas = cas;
			this.$store.state.person = {
				index: index,
				id: data.id,
				name: data.name,
				nationality: this.nationalitys.filter(val => val.id == data.nationality.id)[0],
				residence: data.residence,
				profession: data.profession,
				ident_passport: data.ident_passport,
				doc_passport: data.doc_passport,
				pep: this.options2.filter(val => val.id == data.pep.id)[0],
			};
			this.productDialog = true;
		},
		Add(){
			var person = this.$store.state.person;
			if (person.name == '' || person.name == null) {
				this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
			} else if (person.nationality == null) {
				this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
			} else if (person.residence == '' || person.residence == null) {
				this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
			} else if (person.profession == '' || person.profession == null) {
				this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
			} else if (person.ident_passport == '' || person.ident_passport == null) {
				this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
			} else if (person.pep == null) {
				this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
			} else if (person.doc_passport == null) {
				this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
			} else {	
				var datos = {
					name: this.$store.state.person.name,
					nationality: this.$store.state.person.nationality,
					residence: this.$store.state.person.residence,
					profession: this.$store.state.person.profession,
					ident_passport: this.$store.state.person.ident_passport,
					doc_passport: this.$store.state.person.doc_passport,
					pep: this.$store.state.person.pep,
					del: false,
				};
				if(this.newid){
					datos.id = 0;
					if(this.cas==1){this.$store.state.busines.agent.push(datos);}
					if(this.cas==2){this.$store.state.busines.directors.push(datos);}
					if(this.cas==3){this.$store.state.busines.shareholder1.push(datos);}
					if(this.cas==4){this.$store.state.busines.shareholder2.push(datos);}
				} else {
					var index = this.$store.state.person.index;
					datos.id = this.$store.state.person.id;
					if(this.cas==1){this.$store.state.busines.agent[index] = datos;}
					if(this.cas==2){this.$store.state.busines.directors[index] = datos;}
					if(this.cas==3){this.$store.state.busines.shareholder1[index] = datos;}
					if(this.cas==4){this.$store.state.busines.shareholder2[index] = datos;}
				}
				this.productDialog = false;
			}
		},   
		Uploader(type,data) {
			Consult.Process('Add',{
				inf: data,
			}).then(response => {
				//this.$store.state.error =response;
				this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
				if(response.result){	
					if(type==1){this.$store.state.busines.doc_constituent = response.result;}				
                    if(type==2){this.$store.state.busines.doc_pasaporte = response.result;}		
                    if(type==3){this.$store.state.busines.doc_certificate = response.result;}		
                    if(type==4){this.$store.state.busines.form = response.result;}		
				} 
				const tokgen = new Token(256);
				this.token = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
		Delimg(type,img) {
			Consult.Process('Del',{
				inf: img,
			}).then(response => {
				//this.$store.state.error =response;
				this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
				if(response.result){				
					if(type==1){this.$store.state.busines.doc_constituent = null;}				
                    if(type==2){this.$store.state.busines.doc_pasaporte = null;}		
                    if(type==3){this.$store.state.busines.doc_certificate = null;}		
                    if(type==4){this.$store.state.busines.form = null;}		
				} 
				this.$store.commit('Loading');
			});			
		},
	},


}
</script>